import localForage from 'localforage'
import data from './data/data.json'
import moment from 'moment'

export class DataStore {
  constructor() {
    this.dataStore = localForage.createInstance({
      name: data.organization.name,
      version: 1,
    })
  }

  async getKeys() {
    return this.dataStore
      .keys()
      .then((keys) => {
        //console.log('Keys found', keys)
        return keys
      })
      .catch((err) => {
        console.warn('No date keys to return')
        return []
      })
  }

  async getLength() {
    return this.dataStore
      .length((keys) => {
        return keys
      })
      .catch((err) => {
        console.error('Could not get data length', err)
        return 0
      })
  }

  async deleteKey(key) {
    return this.dataStore.removeItem(key)
  }

  async getData(date) {
    let key = moment(date).format('YYYYMMDD')

    return new Promise((resolve, reject) => {
      this.dataStore.getItem(key).then((response) => {
        resolve(response)
      })
    })
  }

  async setData(date, data) {
    let key = moment(date).format('YYYYMMDD')

    return new Promise((resolve, reject) => {
      this.dataStore.setItem(key, data).then((response) => {
        resolve(response)
      })
    })
  }
}
