import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import 'bootstrap/dist/css/bootstrap.min.css'
import PWAPrompt from 'react-ios-pwa-prompt'

ReactDOM.render(
  <React.StrictMode>
    <App />
    <PWAPrompt timesToShow={3} />
  </React.StrictMode>,
  document.getElementById('root')
)

serviceWorker.register()
