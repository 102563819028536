import React, { Component } from 'react'
import { Form, Card } from 'react-bootstrap/'
import ReactMarkdown from 'react-markdown'

class QuestionWrapper extends Component {
  constructor(props) {
    super(props)

    this.question = props.question
    //console.log(this.question)
  }

  render() {
    const error = this.question.error ? ' error' : ''
    return (
      <Card
        key={this.question.id.toString()}
        id={'question_' + this.question.id.toString()}
        className={'spaced rounded' + error}
      >
        <Card.Body>
          <Card.Title>
            <div className="header">{this.question.title}</div>
            <div className="subHeading">{this.question.subTitle}</div>
          </Card.Title>
          <Form.Group controlId={this.question.id.toString()}>
            <ReactMarkdown
              className="font-weight-light"
              source={this.props.content}
            />
            <Form.Control
              type="range"
              onChange={this.props.handleQuestionUpdate}
              min={this.question.rangeStart}
              max={this.question.rangeEnd}
              step="0.1"
              value={this.question.value}
            />

            <div className="sliderTicks">
              <p>{this.question.rangeStart}</p>
              <p>2</p>
              <p>3</p>
              <p>4</p>
              <p>5</p>
              <p>6</p>
              <p>7</p>
              <p>8</p>
              <p>9</p>
              <p>{this.question.rangeEnd}</p>
            </div>
          </Form.Group>
        </Card.Body>
      </Card>
    )
  }
}

export default QuestionWrapper
