import React, { Component } from 'react'
import { Alert } from 'react-bootstrap/'
import ResultBar from './ResultBar'
import Eap from './Eap'
import moment from 'moment'

export default class Results extends Component {
  availableKeys = []
  eapMessages = []
  maxDays = 7 // Display a maximum of 7 days of data

  constructor(props) {
    super(props)

    this.state = {
      startIndex: null,
      startDate: new Date(),
      showBackArrow: false,
      endIndex: null,
      endDate: new Date(),
      showForwardArrow: false,
      displayData: [],
    }

    this.dataStore = this.props.dataStore

    // Setup class functions
    this.backEntry = this.backEntry.bind(this)
    this.forwardEntry = this.forwardEntry.bind(this)
  }

  async componentDidMount() {
    // Get potential messages
    this.dataStore.getKeys().then((availableKeys) => {
      this.availableKeys = availableKeys
      var dataLength = this.availableKeys.length

      this.setState({
        showBackArrow: dataLength > this.maxDays ? true : false, // If we have more than the display range show the prev arrow
        startIndex: dataLength, // We start from the most recent entry
        showForwardArrow: false, // default is to start at most current entry so hide next arrow
        // Find the data index display up until the max day count
        endIndex:
          dataLength > this.maxDays ? dataLength - this.maxDays : dataLength,
      })

      if (this.availableKeys.length > 0) this.fetchDisplayData(dataLength)
    })
  }

  async fetchDisplayData(index) {
    //debugger

    // Find the earliest up to maxDays entries, or stop
    var fromIndex = index - this.maxDays <= 0 ? 0 : index - this.maxDays

    // Find the last day we will show up to the last date available
    var untilIndex = fromIndex === index ? fromIndex + 1 : index

    this.state.displayData = []

    const startDate = new Date(
      this.availableKeys[fromIndex].replace(
        /(\d{4})(\d{2})(\d{2})/g,
        '$1-$2-$3'
      )
    )

    const endDate = new Date(
      this.availableKeys[untilIndex - 1].replace(
        /(\d{4})(\d{2})(\d{2})/g,
        '$1-$2-$3'
      )
    )

    this.setState((prevState) => ({
      startDate: startDate,
      endDate: endDate,
    }))

    for (var i = fromIndex; i < untilIndex; i++) {
      const date = new Date(
        this.availableKeys[i].replace(/(\d{4})(\d{2})(\d{2})/g, '$1-$2-$3')
      )
      this.dataStore.getData(date).then((response) => {
        this.setState((prevState) => ({
          displayData: [...prevState.displayData, response],
        }))
      })
    }
  }

  // Move chart data back one day
  async backEntry(event) {
    event.preventDefault()

    const startIndex = this.state.startIndex - 1
    const endIndex = this.state.endIndex - 1

    const showBackArrow = startIndex === this.maxDays ? false : true
    const showForwardArrow = true

    // startIndex < this.availableKeys.length ? false : true

    this.setState({
      startIndex: startIndex,
      endIndex: endIndex,
      showBackArrow: showBackArrow,
      showForwardArrow: showForwardArrow,
    })
    this.fetchDisplayData(startIndex)
  }

  // Move chart forward one day
  async forwardEntry(event) {
    event.preventDefault()

    const startIndex = this.state.startIndex + 1
    const endIndex = this.state.endIndex + 1

    const showBackArrow = true
    const showForwardArrow =
      startIndex === this.availableKeys.length ? false : true

    this.setState({
      startIndex: startIndex,
      endIndex: endIndex,
      showBackArrow: showBackArrow,
      showForwardArrow: showForwardArrow,
    })
    this.fetchDisplayData(startIndex)
  }

  render() {
    return (
      <>
        {this.availableKeys.length === 0 ? (
          <Alert key="1" variant="warning">
            No results
          </Alert>
        ) : (
          <>
            <div className="resultBlock spaced">
              <div className="resultHeadingBlock">
                <div className="resultHeading">Results</div>
                <div className="resultRange">
                  {this.state.showBackArrow ? (
                    <div className="link backArrow" onClick={this.backEntry}>
                      &#8592;
                    </div>
                  ) : (
                    ''
                  )}
                  {moment(this.state.startDate).format('MMMM D')}
                  {' - '}
                  {moment(this.state.endDate).format('MMMM D')}
                  {this.state.showForwardArrow ? (
                    <div
                      className="link forwardArrow"
                      onClick={this.forwardEntry}
                    >
                      &#8594;
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div className="resultChart">
                {this.state.displayData.map((data, index) => {
                  return <ResultBar key={index} result={data} index={index} />
                })}
              </div>
              <div className="scale">
                <div>0</div>
                <div>5</div>
                <div>10</div>
              </div>
            </div>
            <div className="eap spaced">
              <Eap />
            </div>
          </>
        )}
        <div className="buttonRow spaced">
          <div className="link" onClick={this.props.toggleDisplay}>
            Back to Questions
          </div>
        </div>
      </>
    )
  }
}
