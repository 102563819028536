import React, { Component } from 'react'
import ReactModal from 'react-modal'
import ReactMarkdown from 'react-markdown'

const importAll = (r) => r.keys().map(r)
const markdownFiles = importAll(
  require.context('../data/privacy', false, /\.md$/)
)

class PrivacyModal extends Component {
  privacyContent = ''

  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
    }

    this.handleOpenModal = this.handleOpenModal.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  async componentDidMount() {
    this.privacyContent = await Promise.all(
      markdownFiles.map((file) => {
        return fetch(file).then((res) => res.text())
      })
    ).catch((err) => console.error(err))

    this.setState({ privacyContent: this.privacyContent[0] })

    //console.log(this.privacyContent)
  }

  handleOpenModal() {
    this.setState({ showModal: true })
  }

  handleCloseModal() {
    this.setState({ showModal: false })
  }

  render() {
    return (
      <>
        <span onClick={this.handleOpenModal}>Privacy Policy</span>
        <ReactModal
          className="modalContent"
          overlayClassName="modalOverlay"
          isOpen={this.state.showModal}
          contentLabel="Privacy Policy"
        >
          <span
            className="closeModalButton topRight"
            onClick={this.handleCloseModal}
          >
            &times;
          </span>

          <ReactMarkdown source={this.state.privacyContent} />
        </ReactModal>
      </>
    )
  }
}

export default PrivacyModal
