import React, { Component } from 'react'
import ReactMarkdown from 'react-markdown'
import { forkJoin } from 'rxjs'

import { eap } from '../data/data.json'
import { DataStore } from '../dataStore'

const importAll = (r) => r.keys().map(r)
const eapFiles = importAll(require.context('../data/eap', false, /\.md$/))

export default class Eap extends Component {
  dataStore = new DataStore()
  eapOptions = eap

  constructor(props) {
    super(props)

    this.state = {
      eapMessage: 'Calculating',
      storedDataKeys: [],
      storedData: [],
    }

    this.eapOptions.sort(this.eapPrioritySort) // Sort message by their priority
  }

  componentDidMount() {
    this.dataStore.getKeys().then((storedKeys) => {
      storedKeys.sort()
      this.setState({ storedDataKeys: storedKeys })

      forkJoin(
        storedKeys.map((data) => {
          return this.dataStore.getData(data)
        })
      ).subscribe((data) => {
        this.setState({ storedData: data })
        this.selectEapMessage(data)
      })
    })
  }

  selectEapMessage(data) {
    var eapIndex = 0

    if (data.length > 0 && data[0] !== null) {
      for (eapIndex; eapIndex <= this.eapOptions.length; eapIndex++) {
        const eapOptions = this.eapOptions[eapIndex]

        const minScore = eapOptions.minScore
        const daysUnder = eapOptions.daysUnder

        if (minScore === null && daysUnder === null) {
          break
        }

        var dayCount = 0
        var conditionMet = true
        var dataIndex = data.length - 1

        for (dataIndex; dataIndex >= 0; dataIndex--) {
          //debugger
          dayCount++

          if (data[dataIndex].score > minScore) {
            conditionMet = false
            break
          }
          if (dayCount >= daysUnder) break
        }

        if (dayCount < daysUnder) continue
        if (conditionMet) break
      }
    }

    const requiredFile = this.eapOptions[eapIndex].file

    console.log('EAP File', requiredFile)

    const mdFile = eapFiles.find((file) => file.includes(requiredFile))

    fetch(mdFile)
      .then((fileContent) => fileContent.text())
      .then((content) => {
        this.setState({ eapMessage: content })
      })
  }

  render() {
    return <ReactMarkdown source={this.state.eapMessage} />
  }

  // Sort the EAP messages by their priority (Highest first)
  eapPrioritySort(a, b) {
    const msgA = a.priority
    const msgB = b.priority

    let comparison = 0
    if (msgA > msgB) {
      comparison = 1
    } else if (msgA < msgB) {
      comparison = -1
    }
    return comparison * -1
  }
}
