import React, { Component } from 'react'
import { Alert, Col, Form, Row } from 'react-bootstrap/'
import QuestionCard from './QuestionCard'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import data from '../data/data.json'

const importAll = (r) => r.keys().map(r)
const markdownFiles = importAll(
  require.context('../data/questions', false, /\.md$/)
)

export default class Questions extends Component {
  questionContent = []
  maxData = 28

  constructor(props) {
    super(props)

    this.state = this.buildState(new Date())
    this.dataStore = this.props.dataStore

    this.errorAlert = React.createRef()

    this.handleQuestionUpdate = this.handleQuestionUpdate.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  // Set up the state variables for the questions
  async componentDidMount() {
    this.dataStore.getData(this.state.date).then((response) => {
      if (response !== null) {
        this.setState(response)
      } else {
        this.buildState(new Date())
      }
    })

    this.questionContent = await Promise.all(
      markdownFiles.map((file) => {
        return fetch(file).then((res) => res.text())
      })
    ).catch((err) => console.error(err))

    this.setState({ questionContent: this.questionContent })

    this.calcSummary()
  }

  buildState = (newDate) => {
    let questions = []
    let questionContent = []

    this.props.questions.forEach((item) => {
      let newQuestion = {
        id: item.id,
        error: false,
        value: item.default,
      }
      questions.push(newQuestion)
      questionContent.push('')
    })

    let newState = {
      date: newDate,
      score: 0,
      sum: 0,
      questions: questions,
      questionContent: this.questionContent,
      error: false,
    }

    return newState
  }

  calcSummary() {
    let questions = this.state.questions

    // Calculate the sum
    let sum = questions.reduce(
      (total, question) => total + parseInt(question.value),
      0
    )

    // Store everything back into the state object
    this.setState({
      sum: sum,
      score: sum / questions.length,
    })
  }

  // DEBUG Display the selected value
  addValueCols = () => {
    let cols = []

    this.state.questions.forEach((question) => {
      cols.push(
        <Col key={question.id}>
          Q {question.id} = {question.value}
        </Col>
      )
    })

    return cols
  }

  handleDateChange(newDate) {
    this.dataStore.getData(newDate).then((response) => {
      if (response !== null) {
        //console.log('found data', response)
        this.setState({
          ...response,
          questionContent: this.questionContent,
        })
      } else {
        console.warn("select date doesn't exist", response)
        this.setState(this.buildState(newDate))
      }
    })
  }

  handleQuestionUpdate(event) {
    event.preventDefault()

    // get out all the different bits bits
    let questions = this.state.questions
    let targetId = parseInt(event.target.id)
    let targetValue = parseInt(event.target.value)

    let index = questions.findIndex((obj) => obj.id === targetId)

    // Update the value for the changed question
    questions[index].value = targetValue
    questions[index].error = targetValue <= 0 ? true : false

    this.calcSummary()
  }

  handleSave(event) {
    event.preventDefault()

    var errors = false

    this.state.questions.forEach((question, index) => {
      const updatedArray = [...this.state.questions]
      if (question.value <= 0) {
        updatedArray[index].error = true
        errors = true
      } else {
        updatedArray[index].error = false
      }
      this.setState({
        questions: updatedArray,
      })
    })

    if (errors) {
      this.setState({ error: true })
      return
    }

    const questionData = { ...this.state }
    delete questionData.questionContent // we don't want to store the text of the questions

    this.dataStore.getLength().then((dataLength) => {
      //console.log('# Entries Stored', dataLength)

      if (dataLength > this.maxData) {
        this.dataStore.getKeys().then((availableKeys) => {
          debugger

          this.dataStore.deleteKey(availableKeys[0])
        })
      }
    })

    this.dataStore
      .setData(this.state.date, questionData)
      .then((response) => {
        //console.log('setData', response)
        this.props.toggleDisplay()
      })
      .catch((err) => {
        console.err('setDate', err)
      })
  }

  render() {
    let devContent = ''

    if (data.devMode) {
      devContent = (
        <div className="development">
          <Row>
            <h3>Development Stuff - Will go away</h3>
          </Row>
          <Row>
            Pick date to add/update
            <DatePicker
              selected={this.state.date}
              onChange={this.handleDateChange}
              name="startDate"
              dateFormat="yyyy-MM-dd"
            />
          </Row>
          <Row>{this.addValueCols()}</Row>
          <Row>
            <Col> Total: {this.state.sum} </Col>
            <Col> Score: {this.state.score} </Col>
          </Row>
        </div>
      )
    }

    return (
      <>
        <div>
          <div className="intro font-weight-light spaced">
            The first step to better looking after you is knowing what part of
            you is most in need of care. The {data.organization.name} Self
            Awareness tracker is a great way to make sure you connect in daily
            with your Mind, Body and Mood.
          </div>
          <Form>
            <Row>
              {this.props.questions.map((question) => {
                // Merge raw question metadata with current value if it exists
                if (this.state.questions.length > question.id - 1) {
                  question.value = this.state.questions[question.id - 1].value
                  question.error = this.state.questions[question.id - 1].error
                }

                return (
                  <QuestionCard
                    question={question}
                    content={this.state.questionContent[question.id - 1]}
                    handleQuestionUpdate={this.handleQuestionUpdate}
                    key={question.id}
                  />
                )
              })}
            </Row>

            <div className="buttonRow spaced">
              <Alert
                variant="danger"
                className={!this.state.error ? 'hidden' : ''}
              >
                Please check the questions above to ensure you have completed
                them all
              </Alert>
              <div className="submit spaced" onClick={this.handleSave}>
                Submit your responses
              </div>
              <div className="link" onClick={this.props.toggleDisplay}>
                View History
              </div>
            </div>
          </Form>
        </div>

        {devContent}
      </>
    )
  }
}
