import React, { Component } from 'react'
import moment from 'moment'

export default class ResultBar extends Component {
  constructor(props) {
    super(props)

    this.result = this.props.result
    this.index = this.props.index
  }

  calcOptions() {
    this.shortScore = this.result.score.toFixed(2)
    this.width = (this.result.score * 10).toFixed(2).toString() + '%'
    this.displayDate = moment(this.result.date).format('MMM/DD')

    switch (true) {
      case this.shortScore < 3:
        this.class = 'score1'
        break
      case this.shortScore < 6:
        this.class = 'score2'
        break
      case this.shortScore < 8:
        this.class = 'score3'
        break
      default:
        this.class = 'score4'
        break
    }
  }

  render() {
    this.calcOptions()

    return (
      <div
        key={this.index}
        style={{
          width: this.width,
        }}
        className={this.class}
      >
        <p>{this.displayDate}</p>
      </div>
    )
  }
}
