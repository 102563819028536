import React, { Component } from 'react'

import Header from './components/Header'
import Footer from './components/Footer'
import Questions from './components/Questions'
import Results from './components/Results'

import { organization, questions } from './data/data.json'
import { DataStore } from './dataStore'
import data from './data/data.json'

import './assets/styles/app.scss'

class App extends Component {
  state = {
    dataStore: new DataStore(),
    showResults: false,
  }

  constructor(props) {
    super(props)

    this.toggleDisplay = this.toggleDisplay.bind(this)

    document.title =
      data.organization.projectTitle + ' | ' + data.organization.name
  }

  toggleDisplay() {
    this.setState({ showResults: !this.state.showResults })
  }

  render() {
    return (
      <div className="app">
        <Header org={organization.name} title={organization.projectTitle} />
        <div>
          {!this.state.showResults ? (
            <Questions
              questions={questions}
              dataStore={this.state.dataStore}
              toggleDisplay={this.toggleDisplay}
            />
          ) : (
            <Results
              dataStore={this.state.dataStore}
              toggleDisplay={this.toggleDisplay}
            />
          )}
        </div>

        <Footer />
      </div>
    )
  }
}

export default App
