import React, { Fragment, Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import PrivacyModal from './PrivacyModal'
import { organization } from '../data/data.json'

export default class Footer extends Component {
  render() {
    let footer = ''

    if (organization.clientRelease) {
      footer = (
        <a
          href="https://selectwellness.com.au"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          <img
            className="brandImage"
            src={process.env.PUBLIC_URL + '/img/brand.svg'}
            alt="Select Wellness"
          />
        </a>
      )
    } else {
      footer = (
        <div className="clientSubtitle">
          Brought to you by
          <a
            href="https://selectwellness.com.au"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Select Wellness
          </a>
        </div>
      )
    }

    return (
      <Fragment>
        <footer>
          <div className="branding">{footer}</div>
          <div className="links">
            <ul>
              <li className="privacyLink">
                <PrivacyModal />
              </li>
              <li>
                <a
                  href="https://blackdogdevelopers.com"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Developed by Black Dog Developers
                </a>
              </li>
              <li>
                <a
                  href="https://boxercox.com"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  Designed by BoxerCox
                </a>
              </li>
            </ul>
          </div>
        </footer>
      </Fragment>
    )
  }
}
