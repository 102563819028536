import React, { Component } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { organization } from '../data/data.json'

export default class Header extends Component {
  state = {
    logo: {
      src: '/img/brand.svg',
      title: organization.name,
    },

    applicationImg: {
      src: '/img/app.svg',
      title: organization.name,
    },
  }

  async componentDidMount() {
    //console.log('client', organization.clientRelease)
    //console.log('images', this.state)
    if (organization.clientRelease) {
      this.setState({
        logo: {
          src: '/img/' + organization.logo,
        },
      })
    }
  }

  render() {
    let clientRelease = ''

    if (organization.clientRelease) {
      clientRelease = (
        <div className="clientSubtitle">
          Brought to you by
          <a
            href="https://selectwellness.com.au"
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Select Wellness
          </a>
        </div>
      )
    }

    return (
      <header className="spaced">
        <div className="brand">
          <img
            className="brandImage"
            src={process.env.PUBLIC_URL + this.state.logo.src}
            alt={this.state.logo.title}
          />
          <div>
            <h3 className="projectTitle">{organization.projectTitle}</h3>
          </div>
          {clientRelease}
        </div>
        <div className="appImage">
          <img
            src={process.env.PUBLIC_URL + this.state.applicationImg.src}
            alt={this.state.applicationImg.title}
          />
        </div>
      </header>
    )
  }
}
